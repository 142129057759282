import config from './firebaseServiceConfig';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
//import 'firebase/analytics';
import 'firebase/performance';
import { v4 as uuidv4 } from 'uuid';
import { Observable } from 'rxjs';

//import {event} from "react-fullstory";
//import {randomString} from "../../utils/utils";

// function updateCreatorToArray(arr, creatorId, creatorName, createdAt) {
//     return arr.map(ta => ({
//         ...ta,
//         creatorId,
//         creatorName,
//         createdAt,
//     }));
// }

// function updateCombineEditableAndCurrentArray(editable, current, equalFN, creatorId, creatorName, createdAt) {
//     const currentArr = current || [];
//     const toAdd = editable.filter(e => !currentArr.some(c => equalFN(e, c)));
//     const currentWithoutRemovedOnes = currentArr.filter(c => editable.some(e => equalFN(e, c)));
//     return currentWithoutRemovedOnes.concat(updateCreatorToArray(toAdd, creatorId, creatorName, createdAt));
// }

// function fullStoryEvent(data) {
//     if(data.event !== 'ping') {
//         event(data.event, data);
//     }
// }


class firebaseService {

    async init()
    {
        if ( firebase.apps.length )
        {
            return;
        }
        if(process.env.NODE_ENV === 'production') {
            const response = await fetch('/__/firebase/init.json');
            const json = await response.json();
            firebase.initializeApp(json);
        } else {
            firebase.initializeApp(config);
        }
        this.db = firebase.firestore();
        this.functions = firebase.functions();
        this.auth = firebase.auth();
        //this.analytics = firebase.analytics();
        this.performance = firebase.performance();
        this.projectId = firebase.apps[0].options.projectId;
    }

    sendSignInLinkToEmail = async (email, language, applicationTypeId) => {
        if ( !firebase.apps.length )
        {
            return;
        }
        const createApplication = this.functions.httpsCallable('createApplication');
        await createApplication({email, language, applicationTypeId});
        window.localStorage.setItem('emailForSignIn', email);
    };

    sendSignInLinkToEmailAdmin = async (email) => {
        if ( !firebase.apps.length )
        {
            return;
        }
        await firebase.auth().sendSignInLinkToEmail(email, {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url: window.location.href,
            // This must be true.
            handleCodeInApp: true,
        });
        window.localStorage.setItem('emailForSignIn', email);
    };

    isSignInWithEmailLink = () => {
        return firebase.auth().isSignInWithEmailLink(window.location.href);
    };

    signInWithEmailLink = (email) => {
        return firebase.auth().signInWithEmailLink(email, window.location.href)
            .then(function(result) {
                // Clear email from storage.
                window.localStorage.removeItem('emailForSignIn');
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
            });
    };

    // createApplication = async () => {
    //     const createApplication = this.functions.httpsCallable('createApplication');
    //     await createApplication({});
    //
    //     return {};
    // };

    submitScreen = async (applicationId, itemsWithAnswers) => {
        const submitScreen = this.functions.httpsCallable('submitScreen');
        await submitScreen({applicationId, itemsWithAnswers});

        return {};
    };

    moveScreenBack = async (applicationId) => {
        const moveScreenBack = this.functions.httpsCallable('moveScreenBack');
        await moveScreenBack({applicationId});

        return {};
    };

    removeFileFromApplication = async (applicationId, fileKey, itemNumbers) => {
        const removeFileFromApplication = this.functions.httpsCallable('removeFileFromApplication');
        await removeFileFromApplication({applicationId, fileKey, itemNumbers});

        return {};
    };

    // uploadDocument = (applicationId, file, uid, itemNumbers) => {
    //     const projectId = this.projectId;
    //     return new Observable(function (observer) {
    //         const alias = uuidv4();
    //
    //         const documentRef = firebase.storage().refFromURL(`gs://${projectId}-uploading-docs/${applicationId}/${file.name}`);
    //     const metadata = {
    //         contentType: file.contentType,
    //         customMetadata: {
    //             uid,
    //             applicationId,
    //             itemNum: itemNumbers.join(','),
    //             alias: alias
    //         }
    //     };
    //     const uploadingTask = documentRef.put(file, metadata);
    //     const uploadedAt = new Date();
    //     uploadingTask.on('state_changed', function(snapshot){
    //         // Observe state change events such as progress, pause, and resume
    //         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //
    //         observer.next({
    //             [alias]: {
    //                 progress,
    //                 uploading: true,
    //                 filename: file.name,
    //                 filesize: file.size,
    //                 isLocal: true,
    //                 uploadedAt
    //             }
    //         });
    //         console.log("running!")
    //         switch (snapshot.state) {
    //             case firebase.storage.TaskState.PAUSED: // or 'paused'
    //                 break;
    //             case firebase.storage.TaskState.RUNNING: // or 'running'
    //
    //                 break;
    //         }
    //     }, function(error) {
    //         observer.next({
    //             [alias]: {
    //                 error: true,
    //                 uploading: false,
    //                 uploaded: false,
    //                 filename: file.name,
    //                 filesize: file.size,
    //                 isLocal: true,
    //                 uploadedAt
    //             }
    //         });
    //         observer.complete();
    //     }, function() {
    //         observer.next({
    //             [alias]: {
    //                 progress: 100,
    //                 uploaded: false,
    //                 uploading: true,
    //                 filename: file.name,
    //                 filesize: file.size,
    //                 isLocal: true,
    //                 uploadedAt
    //             }
    //         });
    //         observer.complete();
    //         // Handle successful uploads on complete
    //         // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //     });
    //     // return await uploadingTask.then(() => ({
    //     //     [fullFileName]: {
    //     //         progress: 100,
    //     //         uploaded: true,
    //     //         filename: file.name,
    //     //         filesize: file.size
    //     //     }
    //     // }));
    //     });
    //
    // };

    uploadDocument = (applicationId, file) => {
        return new Observable(function (observer) {
            const fileName = uuidv4();
            const ext = file.name.split('.').pop();
            const fullFileName = `${fileName}.${ext}`;
            const documentRef = firebase.storage().ref().child(`${applicationId}/${fullFileName}`);
        const uploadingTask = documentRef.put(file);
        uploadingTask.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            observer.next({
                [fullFileName]: {
                    progress,
                    uploading: true,
                    filename: file.name,
                    filesize: file.size
                }
            });
            console.log("running!")
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'

                    break;
            }
        }, function(error) {
            observer.next({
                [fullFileName]: {
                    error: true,
                    uploading: false,
                    uploaded: false,
                    filename: file.name,
                    filesize: file.size
                }
            });
            observer.complete();
        }, function() {
            observer.next({
                [fullFileName]: {
                    progress: 100,
                    uploaded: true,
                    filename: file.name,
                    filesize: file.size,
                    uploadedAt: new Date(),
                }
            });
            observer.complete();
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        });
        // return await uploadingTask.then(() => ({
        //     [fullFileName]: {
        //         progress: 100,
        //         uploaded: true,
        //         filename: file.name,
        //         filesize: file.size
        //     }
        // }));
        });

    };


    deleteDocumentAdmin = (applicationId, stage, name) => {
        return firebase.storage().ref().child(`${applicationId}/${stage}/${name}`).delete();
    };


    uploadAuditLogFilesAdmin = (applicationId, file) => {
        const documentRef = firebase.storage().ref().child(`${applicationId}/Audit Log Files/${file.name}`);//.refFromURL(`gs://${this.projectId}-uploading-docs/${randomString()}${accountId}${path.replace(/\.pdf$/i, '')}.pdf`);
        return  documentRef.put(file);
    };

    uploadDocumentAdmin = (applicationId, file, subfolder) => {
        console.log(file);
        return new Observable(function (observer) {
        const fileName = uuidv4();
        const ext = file.name.split('.').pop();
        const fullFileName = `${fileName}.${ext}`;
        const documentRef = firebase.storage().ref().child(`${applicationId}/${subfolder}/${fullFileName}`);//.refFromURL(`gs://${this.projectId}-uploading-docs/${randomString()}${accountId}${path.replace(/\.pdf$/i, '')}.pdf`);
        const uploadingTask = documentRef.put(file);
        uploadingTask.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            observer.next({
                [fullFileName]: {
                    progress,
                    uploading: true,
                    filename: file.name,
                    filesize: file.size
                }
            });
            //console.log("running!")
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'

                    break;
            }
        }, function(error) {
            console.log(error);
            observer.next({
                [fullFileName]: {
                    error: true,
                    uploading: false,
                    uploaded: false,
                    filename: file.name,
                    filesize: file.size
                }
            });
            observer.complete();
        }, function() {
            observer.next({
                [fullFileName]: {
                    progress: 100,
                    uploaded: true,
                    filename: file.name,
                    filesize: file.size,
                    uploadedAt: new Date(),
                    alias: fullFileName
                }
            });
            observer.complete();
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        });
        // return await uploadingTask.then(() => ({
        //     [fullFileName]: {
        //         progress: 100,
        //         uploaded: true,
        //         filename: file.name,
        //         filesize: file.size
        //     }
        // }));
        });

    };

    setUserForStage = async (applicationId, stage, uid) => {
        const setUserForStage = this.functions.httpsCallable('setUserForStage');
        await setUserForStage({applicationId, stage, uid});

        return {};
    };

    moveToNextStage = async (applicationId) => {
        const moveToNextStage = this.functions.httpsCallable('moveToNextStage');
        await moveToNextStage({applicationId});

        return {};
    };

    moveToBackStage = async (applicationId) => {
        const moveToBackStage = this.functions.httpsCallable('moveToBackStage');
        await moveToBackStage({applicationId});

        return {};
    };

    updateApplicationInternal = async (applicationId, args) => {
        const {notes, recommendation, grantAmount, internalQuestions, internalFiles, filesToNotes} = args;
        const updateApplicationInternal = this.functions.httpsCallable('updateApplicationInternal');
        await updateApplicationInternal({applicationId, notes, recommendation, grantAmount, internalFiles, internalQuestions, filesToNotes});

        return {};
    };

    denyApplication = async (applicationId) => {
        const denyApplication = this.functions.httpsCallable('denyApplication');
        await denyApplication({applicationId});

        return {};
    };

    changeStatusToOnHold = async (applicationId) => {
        const changeStatusToOnHold = this.functions.httpsCallable('changeStatusToOnHold');
        await changeStatusToOnHold({applicationId});

        return {};
    };

    changeStatusToDuplicate = async (applicationId) => {
        const changeStatusToDuplicate = this.functions.httpsCallable('changeStatusToDuplicate');
        await changeStatusToDuplicate({applicationId});

        return {};
    };

    changeStatusToInProgress = async (applicationId) => {
        const changeStatusToInProgress = this.functions.httpsCallable('changeStatusToInProgress');
        await changeStatusToInProgress({applicationId});

        return {};
    };

    getDocument = (accountId, filename) => {
        const documentRef = firebase.storage().ref().child(`/${accountId}/${filename}`);
        return documentRef.getDownloadURL().catch(() => '');
    };

    getDocuments = async (accountId, filenames) => {
        const promisesDocuments = filenames.map(filename => {
            return this.getDocument(accountId, filename).then(link => {
                    return {
                        [filename]: link
                    };
                }
            );
        });
        const documentsLinksObjs = await Promise.all(promisesDocuments);
        return documentsLinksObjs.reduce(function(result, current) {
            return Object.assign(result, current);
        }, {});
    };

    getDocumentAdmin = (accountId, stage, filename) => {
        const documentRef = firebase.storage().ref().child(`/${accountId}/${stage}/${filename}`);
        return documentRef.getDownloadURL().catch(() => '');
    };

    getDocumentsAdmin = async (accountId, stage, filenames) => {
        const promisesDocuments = filenames.map(filename => {
            return this.getDocumentAdmin(accountId, stage, filename).then(link => {
                    return {
                        [filename]: link
                    };
                }
            );
        });
        const documentsLinksObjs = await Promise.all(promisesDocuments);
        return documentsLinksObjs.reduce(function(result, current) {
            return Object.assign(result, current);
        }, {});
    };

    changeApplicationLanguage = async (applicationId, language) => {
        const changeApplicationLanguage = this.functions.httpsCallable('changeApplicationLanguage');
        await changeApplicationLanguage({applicationId, language});

        return {};
    };

    // rejectInviteToken = (token) => {
    //     const reactToInviteToken = this.functions.httpsCallable('reactToInviteToken');
    //     return reactToInviteToken({
    //         action: 'reject',
    //         token: token
    //     });
    // };

    // getUserData = (userId) => {
    //     if ( !firebase.apps.length )
    //     {
    //         return;
    //     }
    //     const {db} = this;
    //     return new Promise(async (resolve, reject) => {
    //
    //         const userSnapshot = await db.collection('users').doc(userId).get();
    //         if(userSnapshot.exists) {
    //             // const accountsSnapshot = await db.collection('accounts').get();
    //             // const accountsList = accountsSnapshot.docs.map(doc => [doc.id, doc.data()]);
    //             // const accounts = Object.fromEntries(accountsList);
    //             // const accountKeys = Object.keys(accounts);
    //             // const user = {
    //             //     ...userSnapshot.data(),
    //             //     accounts,
    //             //     selectedAccount: accountKeys ? accountKeys[0] : ''
    //             // };
    //             const user = userSnapshot.data();
    //             resolve(user);
    //         }
    //     });
    // };
    //
    // updateUserData = (user) => {
    //     if ( !firebase.apps.length )
    //     {
    //         return;
    //     }
    //     return this.db.collection('users')
    //         .doc(user.uid)
    //         .set(user);
    // };
    //
    // onAuthStateChanged = (callback) => {
    //     if ( !this.auth )
    //     {
    //         return;
    //     }
    //     this.auth.onAuthStateChanged(callback);
    // };
    //
    // logSignUpEvent = (providerId, email, uid) => {
    //     if ( !this.analytics ) {
    //         return;
    //     }
    //
    //     const eventParams = {
    //         providerId: providerId,
    //         uid: uid,
    //         email: email
    //     };
    //
    //     if (typeof window !== "undefined") {
    //         if (window.fbq != null) {
    //             window.fbq('trackCustom', 'signUp', eventParams);
    //         }
    //     }
    //     return this.analytics.logEvent('sign_up', eventParams);
    //
    // };
    //
    // createTokenWithAccountsAndResignIn = async () => {
    //     const createToken = this.functions.httpsCallable('createToken');
    //     const res = await createToken();
    //     const signingIn = await this.auth.signInWithCustomToken(res.data.token);
    //     return signingIn;
    // };
    //
    // signInForAuditApi = async () => {
    //     const provider = new firebase.auth.GoogleAuthProvider();
    //     provider.addScope('https://www.googleapis.com/auth/appsmarketplace.license');
    //
    //     return this.auth.signInWithPopup(provider).then(function(result) {
    //         console.log(result);
    //         // This gives you a Google Access Token. You can use it to access the Google API.
    //         //var token = result.credential.accessToken;
    //         // The signed-in user info.
    //         //var user = result.user;
    //         // ...
    //     }).catch(function(error) {
    //         console.error(error)
    //         // Handle Errors here.
    //         //var errorCode = error.code;
    //         //var errorMessage = error.message;
    //         // The email of the user's account used.
    //         //var email = error.email;
    //         // The firebase.auth.AuthCredential type that was used.
    //         //var credential = error.credential;
    //         // ...
    //     });
    // };
    //
    // changeDisplayName = async (displayName) => {
    //     return this.auth.currentUser.updateProfile({
    //         displayName
    //     });
    // };

    createUserTokenAndSignInWithCustomToken = async () => {
        const createUserToken = this.functions.httpsCallable('createUserToken');
        const res = await createUserToken();
        const signingIn = await this.auth.signInWithCustomToken(res.data.token);
        return signingIn;
    };

    signOut = () => {
        if ( !this.auth )
        {
            return;
        }
        this.auth.signOut();
    };


    // uploadDocuments = (uid, accountId, files) => {
    //     files.forEach(file => {
    //         const metadata = {
    //             contentType: 'application/pdf',
    //             customMetadata: {
    //                 uid,
    //                 accountId,
    //                 source: 'web'
    //             }
    //         };
    //         const path = file.path.startsWith('/') ? file.path : `/${file.path}`;
    //         const documentRef = firebase.storage().refFromURL(`gs://${this.projectId}-uploading-docs/${randomString()}${accountId}${path.replace(/\.pdf$/i, '')}.pdf`);
    //         const uploadingTask = documentRef.put(file, metadata);
    //         uploadingTask.on('state_changed', function(snapshot){
    //             // Observe state change events such as progress, pause, and resume
    //             // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //             const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //             console.log('Upload is ' + progress + '% done');
    //             switch (snapshot.state) {
    //                 case firebase.storage.TaskState.PAUSED: // or 'paused'
    //                     console.log('Upload is paused');
    //                     break;
    //                 case firebase.storage.TaskState.RUNNING: // or 'running'
    //                     console.log('Upload is running');
    //                     break;
    //             }
    //         }, function(error) {
    //             // Handle unsuccessful uploads
    //         }, function() {
    //             // Handle successful uploads on complete
    //             // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //             fullStoryEvent({
    //                 event: 'newDocument',
    //                 eventType: 'action',
    //             });
    //         });
    //         // const docRef = accountRef.collection('documents').doc();
    //         // const documentId = docRef.id;
    //         // docRef.set({
    //         //     name: file.name.replace(/\.pdf$/i, ''),
    //         //     originalName: file.name,
    //         //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    //         //     categories: [],
    //         //     creatorId: uid,
    //         //     source: 'web',
    //         // }).then(() => {
    //         //     const metadata = {
    //         //         contentType: 'application/pdf',
    //         //         customMetadata: {
    //         //             uid,
    //         //             accountId,
    //         //             source: 'web'
    //         //         }
    //         //     };
    //         //     const documentRef = firebase.storage().refFromURL(`gs://${this.projectId}-docs/${accountId}-${documentId}.pdf`);
    //         //     const uploadingTask = documentRef.put(file, metadata);
    //         //     uploadingTask.on('state_changed', function(snapshot){
    //         //         // Observe state change events such as progress, pause, and resume
    //         //         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //         //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //         //         console.log('Upload is ' + progress + '% done');
    //         //         switch (snapshot.state) {
    //         //             case firebase.storage.TaskState.PAUSED: // or 'paused'
    //         //                 console.log('Upload is paused');
    //         //                 break;
    //         //             case firebase.storage.TaskState.RUNNING: // or 'running'
    //         //                 console.log('Upload is running');
    //         //                 break;
    //         //         }
    //         //     }, function(error) {
    //         //         // Handle unsuccessful uploads
    //         //     }, function() {
    //         //         // Handle successful uploads on complete
    //         //         // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //         //         fullStoryEvent({
    //         //             event: 'newDocument',
    //         //             eventType: 'action',
    //         //             documentId,
    //         //             from: 'web'
    //         //         });
    //         //     });
    //         // })
    //     });
    //
    // };
    //
    // getThumbnail = (accountId, documentId) => {
    //     const thumbnailRef = firebase.storage().refFromURL(`gs://${this.projectId}-thumbnails/${accountId}/${documentId}.tn.jpg`);
    //     return thumbnailRef.getDownloadURL().catch(() => '');
    // };
    //
    // getThumbnails = async (accountId, documentIds) => {
    //     const promisesDocuments = documentIds.map(id => {
    //         return this.getThumbnail(accountId, id).then(link => {
    //                 return {
    //                     [id]: link
    //                 };
    //             }
    //         );
    //     });
    //     const documentsLinksObjs = await Promise.all(promisesDocuments);
    //     return documentsLinksObjs.reduce(function(result, current) {
    //         return Object.assign(result, current);
    //     }, {});
    // };
    //
    // getFirstPageImage = (accountId, documentId) => {
    //     const thumbnailRef = firebase.storage().refFromURL(`gs://${this.projectId}-thumbnails/${accountId}/${documentId}.1.jpg`);
    //     return thumbnailRef.getDownloadURL().catch(() => '');
    // };
    //
    // getFirstPageImages = async (accountId, documentIds) => {
    //     const promisesDocuments = documentIds.map(id => {
    //         return this.getFirstPageImage(accountId, id).then(link => {
    //                 return {
    //                     [id]: link
    //                 };
    //             }
    //         );
    //     });
    //     const documentsLinksObjs = await Promise.all(promisesDocuments);
    //     return documentsLinksObjs.reduce(function(result, current) {
    //         return Object.assign(result, current);
    //     }, {});
    // };
    //
    //
    // getPDFForDocuments = (accountId, documentId) => {
    //     const documentRef = firebase.storage().refFromURL(`gs://${this.projectId}-docs/${accountId}/${documentId}.pdf`);
    //     return documentRef.getDownloadURL().catch(() => '');
    // };
    //
    // // postActionComment = (accountId, actionId, creatorId, creatorName, comment) => {
    // //     const actionDocRef = this.db.collection('accounts').doc(accountId).collection('actions').doc(actionId);
    // //
    // //     return this.db.runTransaction(function(transaction) {
    // //         return transaction.get(actionDocRef).then(function(actionDoc) {
    // //             if (!actionDoc.exists) {
    // //                 throw "Document does not exist!";
    // //             }
    // //
    // //             const comments = actionDoc.data().comments || [];
    // //             const newComments = comments.concat([{
    // //                 creatorName: creatorName,
    // //                 creatorId: creatorId,
    // //                 comment: comment,
    // //                 timestamp: new Date(),
    // //                 }
    // //             ]);
    // //             transaction.update(actionDocRef, { comments: newComments });
    // //             return newComments;
    // //         });
    // //     }).catch(function(err) {
    // //         // This will be an "population is too big" error.
    // //         console.error(err);
    // //     });
    // //
    // // };
    //
    // createNewAccount = async (name, type, promotionCode, tokenId, uid) => {
    //     if ( !firebase.apps.length )
    //     {
    //         return;
    //     }
    //     const createNewAccount = this.functions.httpsCallable('createNewAccount');
    //     const res = await createNewAccount({
    //         name,
    //         type,
    //         promotionCode,
    //         tokenId
    //     });
    //     fullStoryEvent({
    //         event: 'accountCreated',
    //         eventType: 'action',
    //         accountId: res.data.id
    //     });
    //     const eventParams = {
    //         accountId: res.data.id,
    //         uid: uid,
    //         hasPromotionCode: promotionCode
    //     };
    //     if (typeof window !== "undefined") {
    //         if (window.fbq != null) {
    //             window.fbq('trackCustom', 'newAccount', eventParams);
    //         }
    //     }
    //     this.analytics.logEvent('new_account', eventParams);
    //     return res.data.id;
    // };
    //
    // updateDocument = async (accountId, documentId, creatorId, creatorName, tags, finalize) => {
    //     const updateDocument = this.functions.httpsCallable('updateDocument');
    //     const res = await updateDocument({accountId, documentId, creatorId, creatorName, tags, finalize});
    //     const change = res.data;
    //     const auditLogUpdate = {};
    //     if(change.categoriesBefore !== change.categoriesAfter) {
    //         auditLogUpdate.categoriesBefore = change.categoriesBefore;
    //         auditLogUpdate.categoriesAfter = change.categoriesAfter;
    //     }
    //     if(finalize) {
    //         fullStoryEvent({
    //             accountId,
    //             event: 'documentSavedInVault',
    //             eventType: 'action',
    //             documentId: documentId,
    //             ...auditLogUpdate
    //         });
    //     } else if (Object.keys(auditLogUpdate).length) {
    //         fullStoryEvent({
    //             accountId,
    //             event: 'documentEdited',
    //             eventType: 'action',
    //             documentId: documentId,
    //             ...auditLogUpdate
    //         });
    //     }
    //     return change;
    //
    //
    // };
    //
    // // createOrUpdatePlayer = (accountId, playerId, creatorId, creatorName, form) => {
    // //     const now = new Date();
    // //
    // //     if(playerId) {
    // //         const playerDocRef = this.db.collection('accounts').doc(accountId).collection('players').doc(playerId);
    // //
    // //         return this.db.runTransaction(function (transaction) {
    // //             return transaction.get(playerDocRef).then(function (actionDoc) {
    // //                 if (!actionDoc.exists) {
    // //                     throw "Player does not exist!";
    // //                 }
    // //
    // //                 const player = actionDoc.data();
    // //
    // //                 const newLabels = updateCombineEditableAndCurrentArray(form.labels, player.labels, (editable, current) => (editable.label === current.label), creatorId, creatorName, now);
    // //
    // //                 const newPlayer = {
    // //                     labels: newLabels,
    // //                     type: form.type,
    // //                     name: form.type === 'human' ? `${form.firstName} ${form.lastName}` : form.name,
    // //                     firstName: form.type === 'human' ? form.firstName : '',
    // //                     lastName: form.type === 'human' ? form.lastName : '',
    // //                 };
    // //
    // //                 transaction.update(playerDocRef, newPlayer);
    // //                 return newPlayer;
    // //             });
    // //         });
    // //     } else {
    // //         return this.db.collection('accounts').doc(accountId).collection('players').doc()
    // //             .set({
    // //                 labels: form.labels.map(ta => ({
    // //                     ...ta,
    // //                     creatorId,
    // //                     creatorName,
    // //                     createdAt: now,
    // //                 })),
    // //                 type: form.type,
    // //                 name: form.type === 'human' ? `${form.firstName} ${form.lastName}` : form.name,
    // //                 firstName: form.type === 'human' ? form.firstName : '',
    // //                 lastName: form.type === 'human' ? form.lastName : '',
    // //                 creatorName,
    // //                 creatorId,
    // //                 createdAt: firebase.firestore.FieldValue.serverTimestamp()
    // //             });
    // //     }
    // //
    // // };
    //
    // // updateDocumentApproval = (accountId, documentId, isInVault) => {
    // //     return this.db.collection('accounts').doc(accountId).collection('documents').doc(documentId).update({isInVault});
    // //
    // // };
    //
    // getSearchKey = async (accountId) => {
    //     const getSearchKey = this.functions.httpsCallable('getSearchKey');
    //     const res = await getSearchKey({ accountId: accountId });
    //     return res.data.key;
    // };
    //
    // postAuditLogEvent = async (data) => {
    //     const postAuditLogEvent = this.functions.httpsCallable('postAuditLogEvent');
    //     const res = await postAuditLogEvent(data);
    //     fullStoryEvent(data);
    //     return res.data;
    // };
    //
    // sendRecoveryRequest = async (accountId, name, after, creatorId, creatorName) => {
    //     const sendRecoveryRequest = this.functions.httpsCallable('sendRecoveryRequest');
    //     const res = await sendRecoveryRequest({accountId, name, after: after.toString()});
    //     fullStoryEvent({
    //         userId: creatorId,
    //         creatorName,
    //         event: 'sendRecoveryRequest',
    //         inviteId: res.data.recoveryId,
    //         role: 'admin',
    //         after: after.toString(),
    //         name
    //     });
    //     return res.data;
    // };
    //
    // sendNewInvite = async (accountId, email, creatorId, creatorName) => {
    //     const sendNewInvite = this.functions.httpsCallable('sendNewInvite');
    //     const res = await sendNewInvite({accountId, email});
    //     fullStoryEvent({
    //         userId: creatorId,
    //         creatorName,
    //         event: 'inviteSent',
    //         inviteId: res.data.inviteId,
    //         role: 'admin',
    //         email: email
    //     });
    //     return res.data;
    // };
    //
    // deleteInvite = async (accountId, inviteId) => {
    //     const deleteInvite = this.functions.httpsCallable('deleteInvite');
    //     await deleteInvite({accountId, inviteId});
    //     fullStoryEvent({
    //         accountId,
    //         event: 'inviteDeleted',
    //         eventType: 'action',
    //         inviteId
    //     });
    //     return {};
    // };
    //
    // deleteUser = async (accountId, userId) => {
    //     const deleteUser = this.functions.httpsCallable('deleteUser');
    //     await deleteUser({accountId, userId});
    //
    //     fullStoryEvent({
    //         accountId,
    //         event: 'userDeleted',
    //         eventType: 'action',
    //         userId
    //     });
    //
    //     return {};
    // };
    //
    // acceptInviteToken = async (token, inviteId) => {
    //     const reactToInviteToken = this.functions.httpsCallable('reactToInviteToken');
    //
    //     const res = await reactToInviteToken({
    //         action: 'accept',
    //         token: token
    //     });
    //     fullStoryEvent({
    //         event: 'inviteAccepted',
    //         inviteId: inviteId
    //     });
    //     return res;
    // };
    //
    // getBillingInformation = async (accountId) => {
    //     const getBillingInformation = this.functions.httpsCallable('getBillingInformation');
    //
    //     const res = await getBillingInformation({
    //         accountId
    //     });
    //     return res.data;
    // };
    //
    // updateBillingInformation = async (accountId,
    //                                   name,
    //                                   nameForBilling,
    //                                   emailForBilling,
    //                                   line1,
    //                                   city,
    //                                   country,
    //                                   line2,
    //                                   postalCode,
    //                                   state) => {
    //     if ( !firebase.apps.length )
    //     {
    //         return;
    //     }
    //     const updateBillingInformation = this.functions.httpsCallable('updateBillingInformation');
    //     const res = await updateBillingInformation({
    //         accountId,
    //         name,
    //         nameForBilling,
    //         emailForBilling,
    //         line1,
    //         city,
    //         country,
    //         line2,
    //         postalCode,
    //         state
    //     });
    //     fullStoryEvent({
    //         event: 'accountUpdated',
    //         eventType: 'action',
    //         accountId,
    //         ...res.data
    //     });
    //     return res.data;
    // };
    //
    // updateBillingInformationToken = async (accountId, tokenId, uid) => {
    //     if ( !firebase.apps.length )
    //     {
    //         return;
    //     }
    //     const updateBillingInformation = this.functions.httpsCallable('updateBillingInformation');
    //     const res = await updateBillingInformation({
    //         accountId,
    //         tokenId,
    //     });
    //     fullStoryEvent({
    //         event: 'accountUpdated',
    //         eventType: 'action',
    //         accountId,
    //         ...res.data
    //     });
    //     return res.data;
    // };
    //
    // keepDuplicate = async (accountId, documentId) => {
    //     const handleDuplicate = this.functions.httpsCallable('handleDuplicate');
    //     await handleDuplicate({accountId, documentId, action: 'keep'});
    //
    //     fullStoryEvent({
    //         accountId,
    //         event: 'duplicateDocumentKept',
    //         eventType: 'action',
    //         documentId
    //     });
    //
    //     return {};
    // };
    //
    // deleteDuplicate = async (accountId, documentId) => {
    //     const handleDuplicate = this.functions.httpsCallable('handleDuplicate');
    //     await handleDuplicate({accountId, documentId, action: 'delete'});
    //
    //     fullStoryEvent({
    //         accountId,
    //         event: 'duplicateDocumentDeleted',
    //         eventType: 'action',
    //         documentId
    //     });
    //
    //     return {};
    // };
    //
    // updateApproveDocumentBySuper = async (accountId, documentId) => {
    //     const approveDocumentBySuper = this.functions.httpsCallable('approveDocumentBySuper');
    //     await approveDocumentBySuper({accountId, documentId});
    //
    //     return {};
    // };
    //
    // // rejectInviteToken = (token) => {
    // //     const reactToInviteToken = this.functions.httpsCallable('reactToInviteToken');
    // //     return reactToInviteToken({
    // //         action: 'reject',
    // //         token: token
    // //     });
    // // };
}

const instance = new firebaseService();

export default instance;
