import { EgretLoadable } from "egret";
import WrapperLayout from "../../EgretLayout/Layout2/WrapperLayout";
import React from "react";

const Application = EgretLoadable({
  loader: () => import("./Application")
});
const ApplicationView = EgretLoadable({
  loader: () => import("./ApplicationView")
});

const ApplicationList = EgretLoadable({
  loader: () => import("./ApplicationList")
});


const ApplicationFullList = EgretLoadable({
  loader: () => import("./ApplicationFullList")
});

const applicationRoute = [
  {
    path: "/admin/application/:applicationId",
    exact: true,
    component: (props) => <WrapperLayout><ApplicationView {...props} /></WrapperLayout>
  },
  {
    path: "/admin/application",
    exact: true,
    component: (props) => <WrapperLayout><ApplicationList {...props} /></WrapperLayout>
  },
  {
    path: "/admin/application-full",
    exact: true,
    component: (props) => <WrapperLayout><ApplicationFullList {...props} /></WrapperLayout>
  },
  {
    path: "/app/:applicationTypeId",
    exact: true,
    component: Application
  }
];

export default applicationRoute;