import React, {Component, useState} from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  withStyles,
  CircularProgress
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import firebaseService from './services/firebaseService'
import Typography from "@material-ui/core/Typography";
import { useRouteMatch } from "react-router-dom";
import useApplicationType from "./hooks/useApplicationType";
import SimpleCard from "../egret/components/cards/SimpleCard";


const styles = theme => ({
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

function SignIn({classes, isExpired}) {
  const [email, setEmail] = useState("");
  //const [agreement, setAgreement] = useState("");
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [expiredMessage, setExpiredMessage] = useState(isExpired);
  const match = useRouteMatch("/:applicationTypeId");
  const { applicationType } = useApplicationType(match && match.params && match.params.applicationTypeId);

  if(applicationType === null || applicationType && !applicationType.isEnabled) {
    return <SimpleCard>
      <Typography variant="h4">Application type doesn't exist</Typography>
    </SimpleCard>;
  }

  async function handleFormSubmit(event) {
    setLoading(true);
    await firebaseService.sendSignInLinkToEmail(email)//.catch(() => setLoading(false));
    setFinished(true);
  }

  return (
    <div className="signup flex flex-center w-100 h-100vh">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-32 flex flex-center flex-middle flex-space-between flex-column h-100">
                <div>
                  <img src="/assets/images/logos/strong-logo.png" alt="San Mateo Strong Fund" width="100" />
                </div>
                {/*<div>*/}
                {/*  <img src="/assets/images/logos/smculogo.jpg" alt="SMCU" width="100" />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <img src="/assets/images/logos/logo.png" alt="Flashlight" width="100" />*/}
                {/*</div>*/}
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-36 h-100 bg-light-gray position-relative">
                {!expiredMessage && !finished && <ValidatorForm onSubmit={handleFormSubmit}>
                  <Typography variant="h5">Apply here for the San Mateo County Strong Grant for Small Businesses.</Typography>

                  <TextValidator
                    className="mb-24 w-100"
                    variant="outlined"
                    label="Email"
                    onChange={event => {
                      event.persist();
                      setEmail(event.target.value);

                    }}
                    type="email"
                    name="email"
                    value={email}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid"
                    ]}
                  />
                  {/*<FormControlLabel*/}
                  {/*  className="mb-8"*/}
                  {/*  name="agreement"*/}
                  {/*  onChange={event => {*/}
                  {/*    event.persist();*/}
                  {/*    setAgreement(event.target.value);*/}
                  {/*  }}*/}
                  {/*  control={<Checkbox checked />}*/}
                  {/*  label="I have read and agree to the terms of service."*/}
                  {/*/>*/}
                  <div className="flex flex-middle mb-8">
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        type="submit"
                      >
                        {loading && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}
                        Get Secure Application Link
                      </Button>

                    </div>
                  </div>
                  <Typography>To start an application, or to return to your previously saved application, enter your email address here - and we’ll send you an email with a secure link.</Typography>
                </ValidatorForm>}
                {!expiredMessage && finished && <Typography>Check your email. We’ve sent you an email with a special link for secure access to your application process.</Typography>}
                {expiredMessage && <Typography>Your application link may have expired or you have signed in using a different device. Please click here to request a new link.</Typography>}
                {expiredMessage && <Button
                    variant="contained"
                    color="primary"
                    onClick={event => {
                      setExpiredMessage(false);
                    }}
                >
                  Get New Secure Application Link
                </Button>}
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(SignIn);
