const devConfig = {
    apiKey: "AIzaSyAfAwjKVQ8JvHP4c2A0A7jWcx8UG0ZkaVU",
    authDomain: "flashlightloans-dev.firebaseapp.com",
    databaseURL: "https://flashlightloans-dev.firebaseio.com",
    projectId: "flashlightloans-dev",
    storageBucket: "flashlightloans-dev.appspot.com",
    messagingSenderId: "784139816026",
    appId: "1:784139816026:web:63ffb4149e5081ea2b5c63",
    measurementId: "G-HBLRPPWPHQ"
};
export default devConfig;
