import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SignIn from "./SignIn";
import Loading from "./Loading";
import firebaseService from './services/firebaseService';
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

async function signInWithEmailLink(history) {
  let email = window.localStorage.getItem('emailForSignIn');
  if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    email = window.prompt('Please provide your email for confirmation');
  }

  try {
    await firebaseService.signInWithEmailLink(email);
    history.push(window.location.pathname);
    return true;
  } catch (e) {
    history.push(window.location.pathname);
    return false;
  }
}

function AuthCheck({children}) {
  const auth = useSelector(({firebase}) => firebase.auth);
  const [isSignedInWithToken, setIsSignedInWithToken] = useState(false);
  const [isEmailLinkExpired, setIsEmailLinkExpired] = useState(false);
  const history = useHistory();


  useEffect(() => {
    (async () => {
      if(auth && !auth.isEmpty && !isSignedInWithToken) {
        try {
          await firebaseService.createUserTokenAndSignInWithCustomToken();
          setIsSignedInWithToken(true);
        } catch (e) {
          await firebaseService.signOut();
        }
      } else {
        setIsSignedInWithToken(false);
      }
      if(!isEmailLinkExpired && firebaseService.isSignInWithEmailLink()) {
        const isSignedIn = await signInWithEmailLink(history);
        if(!isSignedIn) {
          setIsEmailLinkExpired(true);
        }
      }
    })();
  }, [auth]);

  if(isEmailLinkExpired) {
    return <SignIn isExpired />;
  } else if(auth && !auth.isEmpty && isSignedInWithToken) {
    return children;
  } else if(auth && auth.isLoaded && auth.isEmpty) {

    return <SignIn />;
  }
  return <Loading />;
}

export default AuthCheck;
