import {useMemo} from 'react';
import {useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";

function useApplicationType(applicationTypeId)
{
    const applicationTypeQuery = useMemo(
        () => ({
            collection: 'applicationTypes',
            doc: applicationTypeId,
        }),
        [applicationTypeId]
    );

    useFirestoreConnect(applicationTypeQuery);
    const applicationType = useSelector(({firestore}) => firestore.data.applicationTypes && firestore.data.applicationTypes[applicationTypeId]);

    return {
        applicationType
    }
}

export default useApplicationType;
