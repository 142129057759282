import "../fake-db";
import "../styles/_app.scss";
import React, {useEffect, useState} from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import EgretTheme from "./EgretLayout/EgretTheme/EgretTheme";
import AppContext from "./appContext";
import history from "history.js";

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import Auth from "./auth/Auth";
import EgretLayout from "./EgretLayout/EgretLayout";
import AuthGuard from "./auth/AuthGuard";
import Loading from "./Loading";
import firebaseService from './services/firebaseService'
import firebase from 'firebase/app'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import AuthCheck from "./AuthCheck";
import { isIE } from 'react-device-detect'


// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: Store.dispatch,
  createFirestoreInstance
};

const App = () => {
  const [fbLoaded, setfbLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      await firebaseService.init();
      setfbLoaded(true);
    })();
  }, []);

    if (isIE) {
        return (
            <div>
                <h1>Hi there. You’re using an outdated browser</h1>
                <p>For a safer and faster user experience use a modern browser like Chrome, Firefox, Safari, Opera, or
                    Edge.</p>
            </div>
        );
    }

  if (!fbLoaded) return <Loading/>;
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <EgretTheme>
          <ReactReduxFirebaseProvider {...rrfProps}>
            {/*<Auth>*/}
              <Router history={history}>
                {/*<AuthGuard>*/}
                {/*  <EgretLayout />*/}
                {/*</AuthGuard>*/}
                {/*<AuthCheck>*/}
                {/*  <EgretLayout />*/}
                {/*</AuthCheck>*/}
                <EgretLayout />

              </Router>
            {/*</Auth>*/}
          </ReactReduxFirebaseProvider>
        </EgretTheme>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
